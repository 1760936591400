<template>
  <div class="border">
    <div class="help-div">
      <h2 class="text-center">HELP</h2>
      <div v-if="auth().user_type == 'user' || auth().user_type == 'hub_admin'">
        <div class="shadow" v-for="(faq, i) in new_faqs" :key="'new-faq-'+i">
          <h4 @click="toggleFaq(i)" class="cursor p-0" style="text-align: left;">
            {{ faq.title }}
            <span v-if="visibleFaq(i)">
              <i class="material-icons">arrow_drop_up</i>
            </span>
            <span v-if="!visibleFaq(i)">
              <i class="material-icons">arrow_drop_down</i>
            </span>
          </h4>
          <div v-if="visibleFaq(i)">
            <div class="faq-box" v-for="(content, j) in faq.contents" :key="'new-faq-content-'+j">
              <div class="faq-content">
                      <h3 v-html="content.h3"></h3>
                      <p v-html="content.text"></p>
                      <ul class="faq-points">
                        <li v-for="(point, pointIndex) in content.points" :key="pointIndex" v-html="point"></li>
                      </ul>
              </div>
              <img :src="image" v-for="(image, k) in content.images" :alt="'new-faq-content-image-'+k" :key="'new-faq-content-image-'+k" class="faq-image">
              <div class="video_container" v-if="content.video">
                <a :href="content.video" target="_blank" class="video-button" v-if="content.video">Watch Video</a>
              </div>
              <div class="note info" v-for="(note, l) in content.notes" :key="'new-faq-content-note-'+l">
                  <div class="note-content" >
                      <p v-html="note"></p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shadow" v-for="(data,i) in faq" :key="i">
        <h4 @click="show(i)" class="cursor p-0" style="text-align: left;">
          {{data.q}}
          <span v-if="visible(i)">
            <i class="material-icons">arrow_drop_up</i>
          </span>
          <span v-if="!visible(i)">
            <i class="material-icons">arrow_drop_down</i>
          </span>
        </h4>
        <div v-if="visible(i)">
          <div v-for="(s,j) in data.src" :key="j">
            <img :src="s" :alt="data.q" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const new_user_faqs = [
  {
    title: 'Login',
    contents: [
      {
        h3:`Login to the <b>Digital Hub Platform</b>:`,
        images: [
          require("@/assets/img/new-user-faqs/login/login.png")
        ],
        text: `<a href="https://kwm.digitalhub.dbla.com.au/" style="color:#007BFF" target="_blank">https://kwm.digitalhub.dbla.com.au</a>`,
        points:[]
      },
      {
        h3:`Enter your King & Wood email address and Click <b>Continue with KWM Account</b> <br><br>The system will take you through to the home screen.`,
        images:[],
        text: null,
        points:[]
      }
    ],
  },
  {
    title: 'Dashboard',
    contents: [
      {
        h3: null,
        images: [
          require("@/assets/img/new-user-faqs/dashboard/dashboard.png")
        ],
        text: null,
        points:[]
      }
    ],
  },
  {
    title: 'Create Booking',
    contents: [
      {
        h3:`STEP 01 – Adding Booking Details`,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.1.3.png")
        ],
        text: `Click on the <b>Courier</b> Tab. <br/><br/> When the accordion opens out, select <b>Courier Booking</b> – the below interface will open up.`,
        points:[]
      },
      {
        h3: null,
        images:[],
        text: `Check that your details are displayed accurately in the requestor details.`,
        points:[]
      },
      {
        h3:`STEP 02 – Add Pickup Address`,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.3.2.png")
        ],
        text: `Then, add the pickup location.`,
        points:[
          `You can type it in using the <b>Pickup Address</b> Tab – this is interfaced 
            with google to validate address and will prompt addresses based on 
            Google maps recognition.`,
            `Use the <b>Company Address Book</b> – search for a user desk, a pickup 
              point on any of the KWM floors in your building. Click <b>Search</b> to find 
              and tick to select.`
        ]
      },
      {
        h3:null,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.3.3.png")
        ],
        text: null,
        points:[
          `Use your <b>Personal Address Book</b> – which will have your frequently 
            sent addresses which may not be a part of the company address 
            book. Click <b>Search</b> to find and tick to select.`
        ],
        notes:[
          `Please note: If you select a floor other than the 8th floor 
            (which houses the mail room) – a messenger will be 
            assigned to come pick it up.`
        ]
      },
      {
        h3:`STEP 03 – Details of Items`,
        images: [],
        text: `Then, select the details of the items you wish to send.`,
        points:[
          `Documents`,
          `Parcel`
        ]
      },
      {
        h3: null,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.3.1.png")
        ],
        text: `Please note you do NOT need to add the weight – the mail room will 
              update the weight. The system will display a default weight of <b>0.2 Kg</b>.<br/><br/>
              You can type out the delivery address in the <b>Deliver Street</b> field and the 
              system will integrate with Google and display matching addresses which 
              you may select. On selecting, the UI will auto complete. 
            `,
        points:[]
      },
      {
        h3:null,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.3.2.png"),
          require("@/assets/img/new-user-faqs/createBooking/3.3.3.png")
        ],
        text: `You may select the delivery address from address books (Personal / Company).`,
        points:[]
      },
      {
        h3:`STEP 04 – Cost Allocation`,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.4.png")
        ],
        text: `Please select the <b>Matter</b> number – if applicable. <br/><br/>
          The matter number is a type search list updated daily based on the 
          matter numbers provided.<br/><br/>
          Alternately, select the <b>GL code</b>. A default GL code is shown based on 
          your department.<br/><br/>
          You can select same, or you can change the GL code by clicking on the 
          <b>Change GL</b> button. 
        `,
        points:[]
      },
      {
        h3:`STEP 05 – Next, Click “Show Available Services”`,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.5.1.png")
        ],
        text: null,
        points:[]
      },
      {
        h3: null,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.5.2.png")
        ],
        text: `The system will show you <b>available services for your booking</b> – Please select one 
            or more preferred services based on your urgency and scroll down.`,
        points:[]
      },
      {
        h3: `Updating Package Details`,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.6.png")
        ],
        text: null,
        points:[],
        notes:[
          `You may leave this section without filling out the fields. The Mail 
            room team have been instructed to complete this section. `
        ]
      },
      {
        h3: `STEP 06 – Review Pickup Details and Delivery Details`,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.7.png")
        ],
        text: null,
        points:[]
      },
      {
        h3: null,
        images: [],
        text: `This section requires you to update your email address and 
          phone details. <br/><br/>
          Also add email and mobile details for the recipient. This is to ensure a 
          higher probability of delivering the item to the consignee on time. 
          `,
        points:[]
      },
      {
        h3: `STEP 07 – Pickup and Delivery Time + Special Instructions`,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.8.1.png")
        ],
        text: `The final task is to update the pickup date and time.`,
        points:[]
      },
      {
        h3: null,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.8.2.png")
        ],
        text: `You may add any additional instructions in the given fields.`,
        points:[]
      },
      {
        h3: null,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.8.3.png")
        ],
        text: `Click <b>SAVE AS DRAFT</b> to save the booking and edit later. It will be saved on 
              the <b>DRAFT</b> section on the side panel.`,
        points:[]
      },
      {
        h3: null,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.8.4.png")
        ],
        text: null,
        points:[]
      },
      {
        h3: null,
        images: [],
        text: `Click <b>SUBMIT & DISPATCH</b>. Your booking will be created. <br/><br/>
          You will be redirected to the <b>View Booking</b> page. <br/><br/>
         You can track the progress of your delivery here:`,
        points:[]
      },
      {
        h3: `Tracking progress of your Shipment`,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.9.1.png"),
        ],
        text: null,
        points:[]
      },
      {
        h3: null,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/3.9.2.png"),
        ],
        text: `Click on the EYE icon`,
        points:[]
      },
      {
        h3: null,
        images: [
          require("@/assets/img/new-user-faqs/createBooking/4.1.png"),
        ],
        text: `In the popup select <b>Courier Shipment Tracking</b> <br/> <br/>
        The system will redirect you to the Allocated Courier site displaying the status of the booking. <br/><br/>
        All required details will be auto populated after the Courier has picked up your item.
        `,
        points:[]
      },
    ],
  }
];

const faqUser = [
  // {
  //   q: "Login",
  //   src: [require("@/assets/img/faq/Login/login.png")]
  // },
  // {
  //   q: "Dashboard",
  //   src: [require("@/assets/img/faq/Dashboard/dashboard.png")]
  // },
  // {
  //   q: "Create Booking",
  //   src: [
  //     require("@/assets/img/faq/CreateBooking/qb1.png"),
  //     require("@/assets/img/faq/CreateBooking/qb2.png"),
  //     require("@/assets/img/faq/CreateBooking/qb3.png"),
  //     require("@/assets/img/faq/CreateBooking/2.png"),
  //     require("@/assets/img/faq/CreateBooking/t1.png"),
  //     require("@/assets/img/faq/CreateBooking/t2.png"),
  //     require("@/assets/img/faq/CreateBooking/t3.png"),
  //     require("@/assets/img/faq/CreateBooking/3.png"),
      
  //     ]
  // },  
  {
    q: "View Pending",
    src: [
    require("@/assets/img/faq/ViewPending/1.png"),
    require("@/assets/img/faq/ViewPending/2.png"),
    require("@/assets/img/faq/ViewPending/3.png"),
    ]
  },
   {
    q: "Postal Services",
    src: [
      require("@/assets/img/faq/PostalServices/1.png"),
      require("@/assets/img/faq/PostalServices/2.png"),
     
      ]
  }, 
   {
    q: "Dispatch courier",
    src: [
      require("@/assets/img/faq/DispatchCourier/1.png")
      ]
  },
   {
    q: "Manifest",
    src: [
      require("@/assets/img/faq/Manifest/1.png")
      ]
  },
  {
    q: "Dispatch Encode",
    src: [
      require("@/assets/img/faq/Dispatch/1.png"),
      require("@/assets/img/faq/Dispatch/2.png"),
      require("@/assets/img/faq/Dispatch/3.png"),
      
      ]
  }, 
  {
    q: "Track and Trace",
    src: [
      require("@/assets/img/faq/Track and Trace/tracktrace.png"),
      require("@/assets/img/faq/Track and Trace/tracktrace2.png")
    ]
  },
  {
    q: "View Receipt",
    src: [require("@/assets/img/faq/ViewReceipt/1.png")]
  },

  {
    q: "Report",
    src: [
    require("@/assets/img/faq/Report/1.png"),
    require("@/assets/img/faq/Report/2.png"),
    require("@/assets/img/faq/Report/3.png"),
    ]
  },
 
  {
    q: "Profile Settings",
    src: [
      require("@/assets/img/faq/Profile settings/profilesettings.png"),
      
    ]
  }, 
  {
    q: "User Service Budget",
    src: [
      require("@/assets/img/faq/UserServiceBudget/1.png"),
      
    ]
  }
];
const faqCSU = [
  {
    q: "Login",
    src: [require("@/assets/img/faq/cs/login/1.png")]
  },
  {
    q: "Dashboard",
    src: [require("@/assets/img/faq/cs/dashboard/1.png")]
  },
  {
    q: "Track and Trace",
    src: [require("@/assets/img/faq/cs/tracktrace/1.png")]
  },
  {
    q: "Profile and Settings",
    src: [require("@/assets/img/faq/cs/profilesettings/1.png")]
  }
];

const new_hub_admin_faqs = [
  {
    title: 'Login',
    contents: [
      {
        h3:`Login to the <b>Digital Hub Platform</b>:`,
        images: [
          require("@/assets/img/new-hub-admin-faqs/login/login.png")
        ],
        text: `<a href="https://kwm.digitalhub.dbla.com.au/" style="color:#007BFF" target="_blank">https://kwm.digitalhub.dbla.com.au</a>`,
        points:[]
      },
      {
        h3:`Enter your King & Wood email address and Click <b>Continue with KWM Account</b> <br><br>The system will take you through to the home screen.`,
        images:[],
        text: null,
        points:[],
        video:'https://prod-api-kwm.digitalhub.dbla.com.au/help-videos/01_Login_Video.mp4'
      }
    ],
  },
  {
    title: 'Dashboard',
    contents: [
      {
        h3: null,
        images: [
          require("@/assets/img/new-hub-admin-faqs/dashboard/dashboard.png")
        ],
        text: null,
        points:[]
      }
    ],
  },
  {
    title: 'Create Booking',
    contents: [
      {
        h3:`STEP 01 – Adding Booking Details`,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.1.3.png")
        ],
        text: `Click on the <b>Courier</b> Tab. <br/><br/> When the accordion opens out, select <b>Courier Booking</b> – the below interface will open up.`,
        points:[],
        video: 'https://prod-api-kwm.digitalhub.dbla.com.au/help-videos/02_Go_to_Courier_Quote_and_Book_Video.mp4'
      },
      {
        h3: null,
        images:[],
        text: `Check that your details are displayed accurately in the requestor details.`,
        points:[]
      },
      {
        h3:`STEP 02 – Add Pickup Address`,
        images: [],
        text: `Then, add the pickup location.`,
        video: 'https://prod-api-kwm.digitalhub.dbla.com.au/help-videos/03_Add_the_Pickup_Address_via_Google_Video.mp4',
        points:[
          `You can type it in using the <b>Pickup Address</b> Tab – this is interfaced 
            with google to validate address and will prompt addresses based on 
            Google maps recognition.`
        ]
      },
      {
        h3:null,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.3.2.png")
        ],
        text: null,
        video: 'https://prod-api-kwm.digitalhub.dbla.com.au/help-videos/04_Add_the_Pickup_Address_via_Company_Address_Book_Video.mp4',
        points:[
            `Use the <b>Company Address Book</b> – search for a user desk, a pickup 
              point on any of the KWM floors in your building. Click <b>Search</b> to find 
              and tick to select.`
        ]
      },
      {
        h3:null,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.3.3.png")
        ],
        text: null,
        points:[
          `Use your <b>Personal Address Book</b> – which will have your frequently 
            sent addresses which may not be a part of the company address 
            book. Click <b>Search</b> to find and tick to select.`
        ],
        video: 'https://prod-api-kwm.digitalhub.dbla.com.au/help-videos/05_Add_the_Pickup_Address_via_Personal_Address_Book_Video.mp4',
        notes:[]
      },
      {
        h3:`STEP 03 – Details of Items`,
        images: [],
        text: `Then, select the details of the items you wish to send.`,
        points:[
          `Documents`,
          `Parcel`
        ]
      },
      {
        h3: null,
        images: [],
        text: `The system will display a default weight of <b>0.2 Kg</b>. 
            `,
        points:[],
        video: 'https://prod-api-kwm.digitalhub.dbla.com.au/help-videos/06_Check_the_Item_and_Weight_Video.mp4',
      },
      {
        h3: null,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.3.1.png")
        ],
        text: `You can type out the delivery address in the <b>Deliver Street</b> field and the 
              system will integrate with Google and display matching addresses which 
              you may select. On selecting, the UI will auto complete. 
            `,
        points:[],
        video: 'https://prod-api-kwm.digitalhub.dbla.com.au/help-videos/07_Add_the_Delivery_Address_via_Data_Entry_Video.mp4',
      },
      {
        h3:null,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.3.2.png"),
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.3.3.png")
        ],
        text: `You may select the delivery address from address books (Personal / Company).`,
        points:[],
        video: 'https://prod-api-kwm.digitalhub.dbla.com.au/help-videos/08_09_Add_the_Delivery_Address_via_Company_Personal_Address_Video.mp4',
      },
      {
        h3:`STEP 04 – Cost Allocation`,
        images: [],
        text: `Please select the <b>Matter</b> number – if applicable.<br/><br/>
          The matter number is a type search list updated daily based on the 
          matter numbers provided.`,
        points:[],
        video: 'https://prod-api-kwm.digitalhub.dbla.com.au/help-videos/10_Check_the_Matter_Number_Video.mp4',
      },
      {
        h3:null,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.4.png")
        ],
        text: `<br/><br/>
          Alternately, select the <b>GL code</b>. A default GL code is shown based on 
          your department.<br/><br/>
          You can select same, or you can change the GL code by clicking on the 
          <b>Change GL</b> button. 
        `,
        points:[],
        video: 'https://prod-api-kwm.digitalhub.dbla.com.au/help-videos/11_Check_the_GL_Code_Video.mp4',
      },
      {
        h3:`STEP 05 – Next, Click “Find Couriers`,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.5.1.png")
        ],
        text: null,
        points:[]
      },
      {
        h3: null,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.5.2.png")
        ],
        text: `The system will show you <b>available courier options</b> – Please select one 
          preferred option based on your urgency and scroll down.`,
        points:[]
      },
      {
        h3: `Updating Package Details`,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.6.png")
        ],
        text: null,
        points:[],
        notes:[]
      },
      {
        h3: `STEP 06 – Review Pickup Details and Delivery Details`,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.7.png")
        ],
        text: null,
        points:[]
      },
      {
        h3: null,
        images: [],
        text: `This section requires you to update your email address and 
          phone details. <br/><br/>
          Also add email and mobile details for the recipient. This is to ensure a 
          higher probability of delivering the item to the consignee on time. 
          `,
        points:[],
        video: 'https://prod-api-kwm.digitalhub.dbla.com.au/help-videos/12_Check_the_Pickup_and_Delivery_Details_Video.mp4',
      },
      {
        h3: `STEP 07 – Pickup and Delivery Time + Special Instructions`,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.8.1.png")
        ],
        text: `The final task is to update the pickup date and time.`,
        points:[],
        video: 'https://prod-api-kwm.digitalhub.dbla.com.au/help-videos/13_Select_the_Pickup_Time_Video.mp4',
      },
      {
        h3: null,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.8.2.png")
        ],
        text: `You may add any additional instructions in the given fields.`,
        points:[]
      },
      {
        h3: null,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.8.3.png")
        ],
        text: `Click <b>SAVE AS DRAFT</b> to save the booking and edit later. It will be saved on 
              the <b>DRAFT</b> section on the side panel.`,
        points:[]
      },
      {
        h3: null,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.8.4.png")
        ],
        text: null,
        points:[]
      },
      {
        h3: null,
        images: [],
        text: `Click <b>SUBMIT & DISPATCH</b>. Your booking will be created. <br/><br/>
          You will be redirected to the <b>View Booking</b> page. <br/><br/>
         You can track the progress of your delivery here:`,
        points:[],
        video: 'https://prod-api-kwm.digitalhub.dbla.com.au/help-videos/14_Click_Submit_and_Dispatch_Video.mp4',
      },
      {
        h3: `Tracking progress of your Shipment`,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.9.1.png"),
        ],
        text: null,
        points:[]
      },
      {
        h3: null,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/3.9.2.png"),
        ],
        text: `Click on the EYE icon`,
        points:[]
      },
      {
        h3: null,
        images: [
          require("@/assets/img/new-hub-admin-faqs/createBooking/4.1.png"),
        ],
        text: `In the popup select <b>Courier Shipment Tracking</b> <br/> <br/>
        The system will redirect you to the Allocated Courier site displaying the status of the booking. <br/><br/>
        All required details will be auto populated after the Courier has picked up your item.
        `,
        points:[]
      },
    ],
  }
];

const faqHubAdmin = [
  // {
  //   q: "Login",
  //   src: [require("@/assets/img/faq/adminHub/login/1.png")]
  // },
  // {
  //   q: "Dashboard",
  //   src: [require("@/assets/img/faq/adminHub/dashboard/1.png")]
  // },
  // {
  //   q: "Create Booking",
  //   src: [
  //     require("@/assets/img/faq/adminHub/createbooking/qb1.png"),
  //     require("@/assets/img/faq/adminHub/createbooking/qb2.png"),
  //     require("@/assets/img/faq/adminHub/createbooking/qb3.png"),
  //     require("@/assets/img/faq/adminHub/createbooking/1.png"),
  //     require("@/assets/img/faq/adminHub/createbooking/2.png"),
  //     require("@/assets/img/faq/adminHub/createbooking/3.png"),
  //     require("@/assets/img/faq/adminHub/createbooking/4.png"),
  //     require("@/assets/img/faq/adminHub/createbooking/5.png"),
  //     require("@/assets/img/faq/adminHub/createbooking/6.png"),
  //     require("@/assets/img/faq/adminHub/createbooking/7.png"),
  //     require("@/assets/img/faq/adminHub/createbooking/8.png"),
      
      
  //   ]
  // },
  {
    q: "Draft",
    src: [
      require("@/assets/img/faq/adminHub/Draft/1.png")
      
    ]
  },

  {
    q: "View Pending",
    src: [
      require("@/assets/img/faq/adminHub/viewpending/1.png"),
      require("@/assets/img/faq/adminHub/viewpending/2.png"),
      require("@/assets/img/faq/adminHub/viewpending/3.png"),
    ]
  },
  {
    q: "Postal Services",
    src: [
      require("@/assets/img/faq/adminHub/PostalServices/1.png"),
      require("@/assets/img/faq/adminHub/PostalServices/2.png"),
     
      ]
  },
    {
    q: "Dispatch Courier",
    src: [
      require("@/assets/img/faq/adminHub/DispatchCourier/1.png"),
      require("@/assets/img/faq/adminHub/PostalServices/2.png"),
     
      ]
  }, 
  {
    q: "Manifest",
    src: [
      require("@/assets/img/faq/adminHub/Manifest/1.png")
      ]
  }, 
  {
    q: "Dispatch Encode",
    src: [
      require("@/assets/img/faq/adminHub/dispatch/1.png"),
      require("@/assets/img/faq/adminHub/dispatch/2.png"),
      require("@/assets/img/faq/adminHub/dispatch/3.png")
    ]
  },
  {
    q: "Hub Dispatch",
    src: [
      require("@/assets/img/faq/adminHub/hubdispatch/1.png"),
      require("@/assets/img/faq/adminHub/hubdispatch/2.png"),
      require("@/assets/img/faq/adminHub/hubdispatch/3.png"),
      
    ]
  },
  {
    q: "Track & Trace",
    src: [
      require("@/assets/img/faq/adminHub/trackandtrace/1.png"),
      require("@/assets/img/faq/adminHub/trackandtrace/2.png"),
    ]
  },
  {
    q: "View Receipt",
    src: [
      require("@/assets/img/faq/adminHub/viewprocessed/1.png"),
    ]
  },
   {
    q: "Invoice Check",
    src: [
      require("@/assets/img/faq/adminHub/Invoicecheck/1.png"),
      require("@/assets/img/faq/adminHub/Invoicecheck/2.png"),

    ]
  },
  {
    q: "Reports",
    src: [
      require("@/assets/img/faq/adminHub/reports/1.png"),
      require("@/assets/img/faq/adminHub/reports/2.png"),
      require("@/assets/img/faq/adminHub/reports/3.png"),
      require("@/assets/img/faq/adminHub/reports/4.png"),
      require("@/assets/img/faq/adminHub/reports/5.png"),
      require("@/assets/img/faq/adminHub/reports/6.png"),
    ]
  },
  {
    q: "Profile & Settings",
    src: [
      require("@/assets/img/faq/adminHub/profilesettings/1.png")
    ]
  },
    {
    q: "User Service Budget",
    src: [
      require("@/assets/img/faq/adminHub/UserServiceBudget/1.png"),
      require("@/assets/img/faq/adminHub/UserServiceBudget/2.png"),

    ]
  },
];
const faqAdmin = [
  {
    q: "Login",
    src: [require("@/assets/img/faq/admin/Login/login-admin.png")]
  },
  {
    q: "Dashboard",
    src: [require("@/assets/img/faq/admin/Dashboard/dashboard-guide-admin.png")]
  },
    {
    q: "Create Booking",
    src: [
      require("@/assets/img/faq/admin/CreateBooking/qb1.png"),
      require("@/assets/img/faq/admin/CreateBooking/qb2.png"),
      require("@/assets/img/faq/admin/CreateBooking/qb3.png")
      
    ]
  },
  {
    q: "Draft",
    src: [
      require("@/assets/img/faq/admin/Draft/1.png")
      
    ]
  },
  {
    q: "View Pending",
    src: [
      require("@/assets/img/faq/admin/ViewPending/viewpending.png"),
      
    ]
  },
  {
    q: "Track and Trace",
    src: [
      require("@/assets/img/faq/admin/Track and Trace/trackandtrace.png"),
      require("@/assets/img/faq/admin/Track and Trace/track-and-trace-details.png")
    ]
  },
  {
    q: "View Receipt",
    src: [
      require("@/assets/img/faq/admin/Booking History/view-processed-guide-admin.png")
    ]
  },
  {
    q: "Address Book",
    src: [
      require("@/assets/img/faq/admin/Address Book/address-book-guide-admin.png"),
      
    ]
  },
  {
    q: "Invoice Check",
    src: [
      require("@/assets/img/faq/admin/Invoicecheck/1.png"),
      require("@/assets/img/faq/admin/Invoicecheck/2.png"),

    ]
  },

  {
    q: "Report",
    src: [
      require("@/assets/img/faq/admin/Report/reports-1.png"),
      require("@/assets/img/faq/admin/Report/reports-2.png"),
      require("@/assets/img/faq/admin/Report/reports-3.png"),
      require("@/assets/img/faq/admin/Report/reports-4.png"),
      require("@/assets/img/faq/admin/Report/reports-5.png"),
      require("@/assets/img/faq/admin/Report/reports-6.png"),
    ]
  },
  // {
  //   q: "Profile Settings",
  //   src: [
  //     require("@/assets/img/faq/admin/Profile settings/profile1.png"),
  //     require("@/assets/img/faq/admin/Profile settings/profile2.png")
  //   ]
  // },
  {
    q: "Password Management",
    src: [
      require("@/assets/img/faq/admin/PasswordManagement/passwordmanagement1.png"),
      require("@/assets/img/faq/admin/PasswordManagement/passwordmanagement2.png")
    ]
  },
  {
    q: "Master Data",
    src: [
      require("@/assets/img/faq/admin/MasterData/1.png"),
      require("@/assets/img/faq/admin/MasterData/2.png"),
      require("@/assets/img/faq/admin/MasterData/3.png"),
      require("@/assets/img/faq/admin/MasterData/4.png"),
      require("@/assets/img/faq/admin/MasterData/5.png"),
      require("@/assets/img/faq/admin/MasterData/6.png"),
      require("@/assets/img/faq/admin/MasterData/7.png"),
      require("@/assets/img/faq/admin/MasterData/8.png"),
      require("@/assets/img/faq/admin/MasterData/9.png"),
      require("@/assets/img/faq/admin/MasterData/10.png"),
      require("@/assets/img/faq/admin/MasterData/11.png"),
      require("@/assets/img/faq/admin/MasterData/12.png"),
      require("@/assets/img/faq/admin/MasterData/13.png"),
      require("@/assets/img/faq/admin/MasterData/14.png"),
      require("@/assets/img/faq/admin/MasterData/15.png"),
      require("@/assets/img/faq/admin/MasterData/16.png"),
      require("@/assets/img/faq/admin/MasterData/17.png"),
      require("@/assets/img/faq/admin/MasterData/18.png"),
      require("@/assets/img/faq/admin/MasterData/19.png"),
      require("@/assets/img/faq/admin/MasterData/20.png"),
      require("@/assets/img/faq/admin/MasterData/21.png")
    ]
  },
  {
    q: "User Management",
    src: [
      require("@/assets/img/faq/admin/UserManagement/usermanagement1.png"),
      require("@/assets/img/faq/admin/UserManagement/usermanagement2.png")
    ]
  },
   {
    q: "User Service Budget",
    src: [
      require("@/assets/img/faq/admin/UserServiceBudget/1.png"),
      

    ]
  },
];

export default {
  name: "Help",
  data() {
    return {
      visibleQuestion: [],
      visibleNewQuestion: [],
      pageDepth: ["Help"],
      user: {},
      faq: faqUser,
      new_faqs:new_user_faqs
    };
  },
  created() {
    this.$emit("breadCrumbData", ["Help"]);
    try {
      this.auth();
    } catch (e) {
      localStorage.clear();
      this.toast.error("You must log in first");
      this.$router.push("/");
    }
    this.checkUser();
  },
  methods: {
    checkUser() {
      this.user = this.auth();
      // console.log(this.user);
      // console.log(this.user.user_type);
      if (this.user.user_type == "super_admin") {
        this.faq = faqAdmin;
      } else if(this.user.user_type == "hub_admin" ){
        this.faq = faqHubAdmin;
        this.new_faqs = new_hub_admin_faqs;
      } else if(this.user.user_type == "customer_service_user"){
        this.faq = faqCSU;
      } else {
        this.faq = faqUser;
        this.new_faqs = new_user_faqs;
      }
    },
    visible(q) {
      return this.visibleQuestion.indexOf(q) >= 0;
    },
    show(q) {
      if (this.visibleQuestion.indexOf(q) >= 0) {
        this.visibleQuestion.splice(this.visibleQuestion.indexOf(q), 1);
      } else {
        this.visibleQuestion.push(q);
      }
    },
    toggleFaq(q) {
      if (this.visibleNewQuestion.indexOf(q) >= 0) {
        this.visibleNewQuestion.splice(this.visibleNewQuestion.indexOf(q), 1);
      } else {
        this.visibleNewQuestion.push(q);
      }
    },
    visibleFaq(q) {
      return this.visibleNewQuestion.indexOf(q) >= 0;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$root.$emit("pageHierarchy", vm.pageDepth);
    });
  }
};
</script>
<style scoped>
.help-div {
  margin-top: 100px;
  padding: 1em;
}
.cursor {
  cursor: pointer;
}
.shadow {
  border: 1px solid #e0e0e0;
  padding: 10px;
  margin: 10px 0;
}
.p-0 {
  padding: 0;
  margin: 10px;
}
h2 {
  padding: 0 0 2em 0;
}
img {
  max-width: 90%;
  margin: 0;
}

.faq-box {
    overflow: hidden;
    max-width: 1200px;
    margin: auto;
}

.faq-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.faq-content {
    padding: 20px;
    margin-bottom: 20px;
}

.faq-content h2 {
    margin-top: 0;
    color: #333;
}

.faq-content h3 {
    margin: 0;
    font-size: 25px;
    /* color: #007BFF; */
}

.faq-content a {
      color: #007BFF !important;
}
.faq-content a:hover {
  text-decoration: underline !important;
}
.faq-content p {
    margin: 10px 0 0;
    color: #000;
    font-size: 20px;
}

.faq-points {
    margin-top: 20px;
    padding-left: 30px;
}

.faq-points li {
    margin-bottom: 10px;
    color: #000;
    list-style-type:disc;
    font-size: 20px;

}
.note {
    position: relative;
    padding: 20px;
    margin: 20px 0;
    border: 1px solid transparent;
    border-radius: 4px;
}

.note.info {
    background-color: #d1ecf1; /* Bootstrap info background color */
    color: #0c5460; /* Bootstrap info text color */
    border-color: #bee5eb; /* Bootstrap info border color */
}

.note .note-content {
    margin-block: 10px;
}

.note .note-content p {
    text-align: center;
    font-size: 25px;
}

.video-button {
    display: inline-block;
    padding: 15px 25px;
    font-size: 20px;
    color: white;
    background-color: #007BFF;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.video-button:hover {
    background-color: #0056b3;
}

.video_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

</style>